import axios from '@axios'

export default {
  namespaced: true,
  state: {
    formOptions: {},
  },
  getters: {
    materialCategoryTypeOptions: state => {
      const { material_category_types: materialCategoryTypes } = state.formOptions
      return materialCategoryTypes ? materialCategoryTypes.options : []
    },
    materialUnitOptions: state => {
      const { material_units: materialUnits } = state.formOptions
      return materialUnits ? materialUnits.options : []
    },
    materialFabricWeightOptions: state => {
      const { material_fabric_weight: materialFabricWeight } = state.formOptions
      return materialFabricWeight ? materialFabricWeight.options : []
    },
    materialQualityOptions: state => {
      const { material_quality: materialQuality } = state.formOptions
      return materialQuality ? materialQuality.options : []
    },
    materialFabricFinishingOptions: state => {
      const { material_fabric_finishing: materialFabricFinishing } = state.formOptions
      return materialFabricFinishing ? materialFabricFinishing.options : []
    },
    colorOptions: state => {
      const { colors } = state.formOptions
      return colors ? colors.options : []
    },
  },
  mutations: {
    UPDATE_FORM_OPTIONS(state, formOptions) {
      state.formOptions = formOptions
    },
  },
  actions: {
    fetchMaterials(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/materials/', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFormOptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/styles/styles_options/')
          .then(response => {
            commit('UPDATE_FORM_OPTIONS', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchMaterial(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/materials/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editMaterial(ctx, materialData) {
      return new Promise((resolve, reject) => {
        const id = materialData.get('id')
        axios
          .patch(`/api/v1/materials/${id}/`, materialData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
